import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Drawer,
  useMediaQuery,
  Avatar,
  ButtonBase,
  Grid,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import { drawerWidth } from "./../../../store/constant";
import { useLocation } from "react-router";
import { useRef } from "react";
import Auth from "../../../utils/auth";
import { IconMenu2 } from "@tabler/icons";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const user = useRef(Auth.getUser());
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesUpSM = useMediaQuery(theme.breakpoints.up("xs"));
  const location = useLocation();
  const appState = location.state?.app;

  function renderToggler() {
    if (user.current) {
      return (
        <ButtonBase
          sx={{
            overflow: "hidden",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: 1,
              background: "#4cb85c",
              color: "#ffffff",
              borderRadius: "0px",
              "&:hover": {
                background: "#4cb85c",
                color: "#ffffff",
              },
            }}
            onClick={drawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      );
    } else {
      return [];
    }
  }

  const drawer = (
    <>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: "95vh",
          }}
        >
          {matchesUpSM && renderToggler()}
          <Box sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <MenuList appState={appState} />
          </Box>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          {matchesUpSM && renderToggler()}
          <MenuList appState={appState} />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={"persistent"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: theme.palette.borderStyle,
            borderLeft: theme.palette.borderStyle,
            borderWidth: "1px",
            borderColor: theme.palette.borderColor.greyBorder,
            marginTop: "24px",
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
